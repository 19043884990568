import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase';
import { collection, doc, getDocs, deleteDoc } from 'firebase/firestore';
import { ref as storageRef, deleteObject } from 'firebase/storage';

const DeleteFileComponent = () => {
    const [pieces, setPieces] = useState([]);
    const [selectedPiece, setSelectedPiece] = useState("");
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchPieces = async () => {
            try {
                const snapshot = await getDocs(collection(db, 'pieces'));
                const pieceList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPieces(pieceList);
            } catch (error) {
                console.error("Error fetching pieces:", error);
                setMessage("Error fetching pieces.");
            }
        };

        fetchPieces();
    }, []);

    const fetchVoices = async (pieceId) => {
        try {
            const voicesCollection = collection(db, `pieces/${pieceId}/voices`);
            const snapshot = await getDocs(voicesCollection);
            const voiceList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setVoices(voiceList);
        } catch (error) {
            console.error("Error fetching voices:", error);
            setMessage("Error fetching voices.");
        }
    };

    const handlePieceChange = async (e) => {
        const pieceId = e.target.value;
        setSelectedPiece(pieceId);
        setSelectedVoice("");
        setVoices([]);
        if (pieceId) {
            await fetchVoices(pieceId);
        }
    };

    const handleDelete = async () => {
        if (!selectedPiece || !selectedVoice) return;

        setDeleting(true);
        setMessage("");

        try {
            // Find the selected voice data
            const voiceData = voices.find(v => v.id === selectedVoice);

            if (!voiceData) {
                setMessage("Selected voice not found.");
                setDeleting(false);
                return;
            }

            // Delete the file from Firebase Storage
            const fileRef = storageRef(storage, voiceData.url);
            await deleteObject(fileRef);

            // Delete the voice document from Firestore
            const voiceDocRef = doc(db, `pieces/${selectedPiece}/voices/${selectedVoice}`);
            await deleteDoc(voiceDocRef);

            // Update the UI
            setVoices(voices.filter(v => v.id !== selectedVoice));
            setSelectedVoice("");
            setMessage("Voice deleted successfully.");
        } catch (error) {
            console.error("Error deleting voice:", error);
            setMessage("Error deleting voice. Please try again.");
        } finally {
            setDeleting(false);
        }
    };

    return (
        <div className="container my-5 p-4 bg-light rounded shadow">
            <h3>Delete Voice</h3>

            {/* Piece Selection */}
            <div className="mb-3">
                <label htmlFor="pieceSelect" className="form-label">Select Piece</label>
                <select
                    id="pieceSelect"
                    value={selectedPiece}
                    onChange={handlePieceChange}
                    className="form-control"
                >
                    <option value="">Select a piece</option>
                    {pieces.map(piece => (
                        <option key={piece.id} value={piece.id}>
                            {piece.pieceName}
                        </option>
                    ))}
                </select>
            </div>

            {/* Voice Selection */}
            <div className="mb-3">
                <label htmlFor="voiceSelect" className="form-label">Select Voice</label>
                <select
                    id="voiceSelect"
                    value={selectedVoice}
                    onChange={(e) => setSelectedVoice(e.target.value)}
                    className="form-control"
                    disabled={!voices.length}
                >
                    <option value="">Select a voice</option>
                    {voices.map(voice => (
                        <option key={voice.id} value={voice.id}>
                            {voice.voice} ({voice.fileName})
                        </option>
                    ))}
                </select>
            </div>

            {/* Delete Button */}
            <button
                onClick={handleDelete}
                className="btn btn-danger"
                disabled={!selectedPiece || !selectedVoice || deleting}
            >
                {deleting ? "Deleting..." : "Delete Voice"}
            </button>

            {message && <p className="mt-3 text-center">{message}</p>}
        </div>
    );
};

export default DeleteFileComponent;

import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Ensure Firebase is configured
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';

const ToggleVisibilityComponent = () => {
    const [pieces, setPieces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchPieces = async () => {
            try {
                const piecesCollection = collection(db, 'pieces');
                const snapshot = await getDocs(piecesCollection);

                const piecesList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setPieces(piecesList);
            } catch (err) {
                console.error("Error fetching pieces:", err);
                setError("Error fetching pieces.");
            } finally {
                setLoading(false);
            }
        };

        fetchPieces();
    }, []);

    const toggleVisibility = async (pieceId, currentVisibility) => {
        try {
            const pieceDocRef = doc(db, 'pieces', pieceId);
            const newVisibility = !currentVisibility;

            // Update Firestore with the new visibility state
            await updateDoc(pieceDocRef, { visible: newVisibility });

            // Update local state to reflect the change
            setPieces((prevPieces) =>
                prevPieces.map((piece) =>
                    piece.id === pieceId ? { ...piece, visible: newVisibility } : piece
                )
            );
        } catch (err) {
            console.error("Error updating visibility:", err);
            setError("Error updating visibility. Please try again.");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-danger text-center mt-5">{error}</div>;
    }

    return (
        <div className="toggle-visibility-component">
            <h3>Toggle Piece Visibility</h3>
            <ul className="list-group">
                {pieces.map((piece) => (
                    <li
                        key={piece.id}
                        className="list-group-item d-flex justify-content-between align-items-center"
                    >
                        <span>{piece.pieceName}</span>
                        <button
                            className={`btn btn-sm ${
                                piece.visible === false ? 'btn-secondary' : 'btn-success'
                            }`}
                            onClick={() =>
                                toggleVisibility(piece.id, piece.visible === false ? false : true)
                            }
                        >
                            {piece.visible === false ? (
                                <i className="bi bi-eye-slash"></i> // Hidden
                            ) : (
                                <i className="bi bi-eye"></i> // Visible
                            )}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ToggleVisibilityComponent;

import React, { useState, useEffect } from 'react';
import { storage, db } from './firebase'; // Firebase configuration
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, collection, setDoc, getDocs, getDoc } from 'firebase/firestore';

const UploadComponent = () => {
    const [file, setFile] = useState(null);
    const [pieceName, setPieceName] = useState("");
    const [isNewPiece, setIsNewPiece] = useState(false); // Determines if the user is adding a new piece
    const [existingPieces, setExistingPieces] = useState([]);
    const [lyrics, setLyrics] = useState("");
    const [bpm, setBpm] = useState("");
    const [voice, setVoice] = useState("");
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchExistingPieces = async () => {
            try {
                const piecesCollection = collection(db, 'pieces');
                const snapshot = await getDocs(piecesCollection);
                const pieces = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setExistingPieces(pieces);
            } catch (error) {
                console.error("Error fetching pieces:", error);
            }
        };

        fetchExistingPieces();
    }, []);

    const handlePieceSelection = async (selectedPiece) => {
        if (selectedPiece === "new") {
            setIsNewPiece(true);
            setPieceName("");
            setLyrics("");
            setBpm("");
        } else {
            setIsNewPiece(false);
            setPieceName(selectedPiece);

            // Fetch metadata for the selected piece
            const pieceDocRef = doc(db, 'pieces', selectedPiece);
            const pieceDoc = await getDoc(pieceDocRef);
            if (pieceDoc.exists()) {
                const data = pieceDoc.data();
                setLyrics(data.lyrics || "");
                setBpm(data.bpm || "");
            } else {
                console.error("Selected piece metadata not found.");
            }
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        if (!file || !pieceName || !voice) {
            setMessage("Please select a file, specify a piece name, and select a voice.");
            return;
        }

        setUploading(true);
        setMessage("");

        try {
            // Upload the file to Firebase Storage
            const storagePath = `audio/${pieceName}/${voice}/${file.name}`;
            const fileRef = storageRef(storage, storagePath);
            await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(fileRef);

            // Save piece metadata (if new) and voice metadata in Firestore
            const pieceDocRef = doc(db, 'pieces', pieceName);
            if (isNewPiece) {
                await setDoc(pieceDocRef, {
                    pieceName,
                    lyrics,
                    bpm,
                    createdAt: new Date()
                });
            }

            const voiceDocRef = doc(pieceDocRef, 'voices', voice);
            await setDoc(voiceDocRef, {
                voice,
                url: downloadURL,
                fileName: file.name,
                createdAt: new Date()
            });

            setMessage("File uploaded successfully!");
            setFile(null);
            setPieceName("");
            setLyrics("");
            setBpm("");
            setVoice("");
            setIsNewPiece(false);
        } catch (error) {
            console.error("Error uploading file or saving metadata:", error);
            setMessage("Error uploading file. Please try again.");
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="container my-5 p-4 bg-light rounded shadow">
            <h3>Upload Audio</h3>
            <form onSubmit={handleUpload}>
                <div className="mb-3">
                    <label htmlFor="pieceSelection" className="form-label">Select or Add a Piece</label>
                    <select
                        id="pieceSelection"
                        className="form-control"
                        onChange={(e) => handlePieceSelection(e.target.value)}
                        value={isNewPiece ? "new" : pieceName}
                    >
                        <option value="">Select a piece</option>
                        {existingPieces.map((piece) => (
                            <option key={piece.id} value={piece.id}>
                                {piece.pieceName}
                            </option>
                        ))}
                        <option value="new">Add a new piece</option>
                    </select>
                </div>

                {(isNewPiece || pieceName) && (
                    <>
                        {isNewPiece && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="newPieceName" className="form-label">New Piece Name</label>
                                    <input
                                        type="text"
                                        id="newPieceName"
                                        value={pieceName}
                                        onChange={(e) => setPieceName(e.target.value)}
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="newLyrics" className="form-label">Lyrics</label>
                                    <textarea
                                        id="newLyrics"
                                        value={lyrics}
                                        onChange={(e) => setLyrics(e.target.value)}
                                        className="form-control"
                                        rows="4"
                                        placeholder="Enter the lyrics for the piece"
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="newBPM" className="form-label">BPM</label>
                                    <input
                                        type="number"
                                        id="newBPM"
                                        value={bpm}
                                        onChange={(e) => setBpm(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter the BPM for the piece"
                                        required
                                    />
                                </div>
                            </>
                        )}

                        {!isNewPiece && pieceName && (
                            <>
                                <div className="mb-3">
                                    <label className="form-label">Lyrics</label>
                                    <p>{lyrics || "No lyrics available for this piece."}</p>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">BPM</label>
                                    <p>{bpm || "No BPM available for this piece."}</p>
                                </div>
                            </>
                        )}

                        <div className="mb-3">
                            <label htmlFor="voice" className="form-label">Voice</label>
                            <input
                                type="text"
                                id="voice"
                                value={voice}
                                onChange={(e) => setVoice(e.target.value)}
                                placeholder="e.g., Soprano, Alto, Tenor, Bass"
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="file" className="form-label">Choose Audio File</label>
                            <input
                                type="file"
                                id="file"
                                accept="audio/*"
                                onChange={handleFileChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <button type="submit" className="btn btn-primary" disabled={uploading}>
                            {uploading ? "Uploading..." : "Upload File"}
                        </button>
                    </>
                )}
            </form>

            {message && <p className="mt-3 text-center">{message}</p>}
        </div>
    );
};

export default UploadComponent;

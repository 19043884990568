import React, { useEffect, useState } from 'react';
import SyncAudioPlayer from './SyncAudioPlayer';
import { db, analytics} from './firebase'; // Ensure Firebase config is correctly imported
import { collection, getDocs } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';

const AudioPlayer = () => {
    const [audioFiles, setAudioFiles] = useState({});
    const [pieces, setPieces] = useState([]); // List of pieces for dropdown
    const [selectedPiece, setSelectedPiece] = useState(""); // Selected piece for filtering
    const [activePlayer, setActivePlayer] = useState(null);

    useEffect(() => {
        const fetchPiecesAndVoices = async () => {
            try {
                const piecesCollection = collection(db, 'pieces');
                const piecesSnapshot = await getDocs(piecesCollection);

                const audioFilesMap = {};
                const piecesList = [];

                for (const pieceDoc of piecesSnapshot.docs) {
                    const pieceData = pieceDoc.data();
                    
                    // Skip pieces that are not visible
                     if (pieceData.visible === false) continue;
                    
                     const pieceName = pieceData.pieceName;
                    piecesList.push(pieceName);

                    // Include lyrics in the audioFilesMap
                    const lyrics = pieceData.lyrics || "Lyrics not available.";

                    // Fetch voices for each piece
                    const voicesCollection = collection(db, `pieces/${pieceDoc.id}/voices`);
                    const voicesSnapshot = await getDocs(voicesCollection);

                    const voices = voicesSnapshot.docs.map(voiceDoc => ({
                        ...voiceDoc.data(),
                        id: voiceDoc.id,
                        lyrics
                    }));

                    audioFilesMap[pieceName] = voices.map(voice => ({
                        src: voice.url,
                        voice: voice.voice || "Unknown",
                        lyrics: voice.lyrics
                    }));
                }

                setPieces(piecesList);
                setAudioFiles(audioFilesMap);
            } catch (error) {
                console.error("Error fetching pieces and voices:", error);
            }
        };

        fetchPiecesAndVoices();
    }, []);

    const handlePlayerActivate = (id) => {
        logEvent(analytics,'piece_activated',{pieceName: pieces[id]});
        setActivePlayer(id);
    };

    // Filter audio files based on the selected piece
    const filteredPieces = selectedPiece
        ? { [selectedPiece]: audioFiles[selectedPiece] }
        : audioFiles;

    return (
        <div className="container my-5 p-4 bg-light rounded shadow">
            {/* Header Section */}
            <div className="text-center mb-4">
                <h1 className="mb-3">Audio Playlist</h1>

                {/* Dropdown for selecting a piece */}
                <select
                    value={selectedPiece}
                    onChange={(e) => setSelectedPiece(e.target.value)}
                    className="form-select mx-auto"
                    style={{ maxWidth: '400px' }}
                >
                    <option value="">Select a piece...</option>
                    {pieces.map((piece, index) => (
                        <option key={index} value={piece}>
                            {piece}
                        </option>
                    ))}
                </select>
            </div>

            <hr />

            {/* Audio Content Section */}
            <div>
                {Object.keys(filteredPieces).map((piece, index) => (
                    <div className="mb-4" key={piece}>
                        <h2 className="text-primary">{piece}</h2>

                        {/* Sync Audio Player for Each Piece */}
                        <SyncAudioPlayer
                            audioFiles={filteredPieces[piece]}
                            id={index}
                            isActive={activePlayer === index}
                            onActivate={() => handlePlayerActivate(index)}
                            lyrics={audioFiles[piece][0]?.lyrics || "Lyrics not available."}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AudioPlayer;

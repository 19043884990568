import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const SyncAudioPlayer = ({ id, audioFiles, isActive, onActivate, lyrics }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [muteStatus, setMuteStatus] = useState(audioFiles.map(() => false));

    const audioRefs = useRef([]);

    const initializeAudio = () => {
        audioRefs.current = audioFiles.map((file) => {
            const audio = new Audio(file.src);
            audio.crossOrigin = "anonymous";
            audio.preload = 'auto';

            audio.onloadedmetadata = () => setDuration(audio.duration);
            audio.ontimeupdate = () => setCurrentTime(audio.currentTime);

            // Handle playback end
            audio.addEventListener("ended", () => {
                const allEnded = audioRefs.current.every(a => a.ended);
                if (allEnded) {
                    setIsPlaying(false);
                }
            });

            return audio;
        });
    };

    const togglePlayPause = () => {
        if (!isPlaying) {
            initializeAudio();
            audioRefs.current.forEach(audio => audio.play());
            setIsPlaying(true);
        } else {
            audioRefs.current.forEach(audio => audio.pause());
            setIsPlaying(false);
        }
    };

    const handleMuteToggle = (index) => {
        const audio = audioRefs.current[index];
        const isMuted = muteStatus[index];
        audio.muted = !isMuted;

        setMuteStatus((prevStatus) => {
            const newStatus = [...prevStatus];
            newStatus[index] = !isMuted;
            return newStatus;
        });
    };

    const handleProgressChange = (e) => {
        const newTime = e.target.value;
        setCurrentTime(newTime);
        audioRefs.current.forEach(audio => (audio.currentTime = newTime));
    };

    const handleComponentClick = () => {
        if (!isActive) {
            onActivate(id);
        }
    };

    return (
        <div
            className={`card p-3 ${isActive ? 'border-primary' : 'border-secondary'}`}
            style={{ borderRadius: '15px', maxWidth: '500px', margin: '20px auto', cursor: 'pointer', borderWidth: '2px' }}
            onClick={handleComponentClick}
        >

            <div className="d-flex justify-content-center mb-3">
                <button onClick={togglePlayPause} className="btn btn-primary btn-lg" disabled={!isActive}>
                    <i className={`bi ${isPlaying ? 'bi-pause-fill' : 'bi-play-fill'}`}></i>
                </button>
            </div>

            <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleProgressChange}
                className="form-range"
                style={{ margin: '10px 0' }}
                disabled={!isActive}
            />

            <div className="d-flex flex-wrap justify-content-around">
                {audioFiles.map((file, index) => (
                    <div key={index} className="d-flex align-items-center my-2">
                        <span className="me-2">{file.voice}</span>
                        <button
                            onClick={() => handleMuteToggle(index)}
                            className="btn btn-outline-primary"
                            disabled={!isActive}
                        >
                            <i className={`bi ${muteStatus[index] ? 'bi-volume-mute-fill' : 'bi-volume-up-fill'}`}></i>
                        </button>
                    </div>
                ))}
            </div>

            {isActive && lyrics && (
                <div className="mb-3">
                    <h5 className="text-center">Lyrics</h5>
                    <p className="text-muted">{lyrics}</p>
                </div>
            )}

        </div>
    );
};

export default SyncAudioPlayer;

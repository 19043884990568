import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UploadPage from './UploadPage';
import AudioPlayer from './AudioPlayer';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

function App() {
    useEffect(() => {
        // Log an event when the app is opened
        logEvent(analytics, 'app_open');
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/upload" element={<UploadPage />} />
                <Route path="*" element={<AudioPlayer />} />
            </Routes>
        </Router>
    );
}

export default App;
